// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { SessionProvider, useSession } from './contexts/SessionContext';
import Login from './components/Login';
import PerformingMedia from './aibooks/PerformingMedia/PerformingMedia';
import './App.css';

const ProtectedRoute = ({ children }) => {
  const { sessionId } = useSession();
  if (!sessionId) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route 
        path="/performing-media" 
        element={
          <ProtectedRoute>
            <PerformingMedia />
          </ProtectedRoute>
        } 
      />
    </Routes>
  );
}

function App() {
  return (
    <SessionProvider>
      <Router>
        <AppRoutes />
      </Router>
    </SessionProvider>
  );
}

export default App;
// src/components/MessageBubble.js
import React from 'react';
import { Box, Avatar, Typography, Tooltip, IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { marked } from 'marked';

const processMarkdown = (text) => {
  return marked.parse(text);
};

const MessageBubble = ({ setSidebarOpen, msg, handleSpeech, botHasResponded, darkMode, speechSynth, username }) => (
  <Box className={`message-bubble ${msg.sender}`}>
    <Avatar src={msg.avatar} alt={`${msg.sender} Avatar`} />
    <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px' }}>
      <Typography variant="body1" component="h4" style={{ fontWeight: 'bold' }}>
        {msg.sender === 'user' ? username : 'A.I.nfante'}
      </Typography>
      {typeof msg.text === 'string' ? (
        <Typography dangerouslySetInnerHTML={{ __html: processMarkdown(msg.text) }} />
      ) : (
        msg.text
      )}
      {msg.sender === 'bot' && botHasResponded && (
        <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <Tooltip title="Perchè questa risposta?">
            <IconButton onClick={() => setSidebarOpen(true)} disabled={!botHasResponded} style={{ color: darkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)', fontSize: '.5em' }}>
              <LiveHelpIcon fontSize="small" style={{ opacity: '0.7' }}/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Copia il testo">
            <IconButton onClick={() => navigator.clipboard.writeText(msg.text)} style={{ color: darkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)', fontSize: '.5em' }}>
              <FileCopyIcon fontSize="small" style={{ opacity: '0.7' }}/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Pronuncia il testo">
            <IconButton onClick={() => handleSpeech(msg.text)} style={{ color: darkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)', fontSize: '.5em'}}>
              {speechSynth && speechSynth.speaking ? <VolumeOffIcon fontSize="small" style={{ opacity: '0.7' }} /> : <VolumeUpIcon fontSize="small" style={{ opacity: '0.7' }} />}
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  </Box>
);

export default MessageBubble;
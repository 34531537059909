// src/components/Login.js
import React, { useState, useEffect } from 'react';
import { useSession } from '../contexts/SessionContext';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Typography, Container, IconButton } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#A9A9A9',
    },
  },
});

const Login = () => {
  const [username, setUsername] = useState('');
  const { login, sessionId } = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a saved session
    const savedSession = localStorage.getItem('sessionId');
    if (savedSession) {
      login(savedSession);
      navigate('/performing-media');
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(username);
    localStorage.setItem('sessionId', username);
    navigate('/performing-media');
  };

  const handleGoogleLogin = () => {
    // Implementare l'autenticazione Google qui
    console.log('Google login not implemented yet');
  };

  if (sessionId) {
    navigate('/performing-media');
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Performing Media - un futuro remoto
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleLogin}
              sx={{ mt: 1, mb: 2 }}
            >
              Login with Google
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
import React from 'react';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ChatIcon from '@mui/icons-material/Chat';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import './Sidebar.css';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


export default function Sidebar({ open, onClose, response }) {
    const [tab, setTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const isResponseValid = (response) => {
        return response && response.why && response.why.memory;
    };

    // Check if the specific memory type is available
    const hasMemoryType = (type) => {
        return isResponseValid(response) && response.why.memory[type];
    };

    return (
        <div id="sidebar">
            <Drawer anchor="right" open={open} onClose={onClose}>
                <div className="drawer-content" style={{ padding: 20, minWidth:'40vw', position: 'relative' }}>
                    {/* Add media query for mobile portrait */}
                    <style>{`
                        @media (max-width: 600px) {
                            div {
                                width: 100vw;
                            }
                        }
                    `}</style>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box display="flex" justifyContent="flex-end">
                            <IconButton onClick={onClose} style={{ position: 'absolute', right: 10, top: 10 }}>   
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Perchè questa risposta?</Typography>
                        <Tabs value={tab} 
                            onChange={handleTabChange} 
                            centered
                            variant = 'fullWidth'
                            indicatorColor="secondary"
                            sx={{
                                width: '100%',
                                minHeight: 'auto', // Override the minimum height
                                '& .MuiTab-root': {
                                minHeight: 'auto', // Override the minimum height of the individual tabs
                                margin:'40px 0px 20px',
                                },
                            }}>
                            <Tab
                            label="Episodic"
                            icon={<ChatIcon style={{ fontSize: 20, marginRight:5 }} />}
                            style={{ flexDirection: 'row' }}
                            sx={{ 
                                flex: 0.8,
                                textTransform: 'none', 
                                fontSize: '0.875rem',
                                padding: '6px 12px',
                                borderRadius: '3px',
                                margin: '0px 20px',
                                '&.Mui-selected': {
                                color: 'white',
                                backgroundColor: '#48a498',
                                },
                            }}
                            />
                            <div style={{ width: '20px' }} /> {/* Elemento vuoto per creare spazio */}  
                            <Tab
                            label="Declarative"
                            icon={<DescriptionIcon style={{ fontSize: 20, marginRight:5  }} />}
                            style={{ flexDirection: 'row' }}
                            sx={{ 
                                flex: 0.8,
                                textTransform: 'none', 
                                fontSize: '0.875rem',
                                padding: '3px 12px',
                                borderRadius: '3px',
                                margin: '0px 20px',
                                '&.Mui-selected': {
                                color: 'white',
                                backgroundColor: '#48a498',
                                },
                            }}
                            />
                            <div style={{ width: '20px' }} /> {/* Elemento vuoto per creare spazio */}
                            <Tab
                            label="Procedural"
                            icon={<SettingsSuggestIcon style={{ fontSize: 20, marginRight:5  }} />}
                            style={{ flexDirection: 'row' }}
                            sx={{ 
                                flex: 0.8,
                                textTransform: 'none', 
                                fontSize: '0.875rem',
                                padding: '3px 12px',
                                borderRadius: '3px',
                                margin: '0px 20px',
                                '&.Mui-selected': {
                                color: 'white',
                                backgroundColor: '#48a498',
                                },
                            }}
                            />
                        </Tabs>
                        <Divider sx={{ width: '70%', margin: '0 auto 40px', borderColor: 'rgba(0,0,0,.2)' }} />
                    </Box>
                    {tab === 0 && hasMemoryType('episodic') && response.why.memory.episodic.map((item, index) => (
                        <Box position="relative" key={index} sx={{ marginBottom: 4 }}>
                            <Card  elevation={0} key={index} sx={{ backgroundColor: '#f5f5f5', paddingTop: '10px', borderRadius: '10px'}}>
                                <Box 
                                    sx={{ 
                                    position: 'absolute', 
                                    top: '-0px', 
                                    left: '50%', 
                                    transform: 'translate(-50%, -50%)', 
                                    backgroundColor: 'black', 
                                    color: 'white', 
                                    borderRadius: '6px', 
                                    width: 50, 
                                    height: 20, 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'center',
                                    fontWeight: 'bold', 
                                    fontSize: '0.8rem'
                                    }}
                                >
                                    <Typography variant="body" component="div">
                                    {item.score.toFixed(3)}
                                    </Typography>
                                </Box>
                            <CardContent>
                                <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                                    {item.page_content}
                                </Typography>
                                <Box display="flex" justifyContent="space-between" marginTop={2}>
                                    <Box sx={{ maxWidth: '65%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <Typography variant="caption">
                                            {item.metadata.source}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ maxWidth: '30%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <Typography variant="caption">
                                            {new Date(item.metadata.when * 1000).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    ))}
                    {tab === 2 && hasMemoryType('declarative') && response.why.memory.declarative.map((item, index) => (
                        <Box position="relative" key={index} sx={{ marginBottom: 4 }}>
                            <Card  elevation={0} key={index} sx={{ backgroundColor: '#f5f5f5', paddingTop: '10px',  borderRadius: '10px'}}>
                                <Box 
                                    sx={{ 
                                        position: 'absolute', 
                                        top: '-0px', 
                                        left: '50%', 
                                        transform: 'translate(-50%, -50%)', 
                                        backgroundColor: 'black', 
                                        color: 'white', 
                                        borderRadius: '6px', 
                                        width: 50, 
                                        height: 20, 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        fontWeight: 'bold', 
                                        fontSize: '0.8rem'
                                    }}
                                >
                                    <Typography variant="body" component="div">
                                    {item.score.toFixed(3)}
                                    </Typography>
                                </Box>
                            <CardContent>
                                <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                                    {item.page_content}
                                </Typography>
                                <Box display="flex" justifyContent="space-between" marginTop={2}>
                                    <Box sx={{ maxWidth: '65%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <a href={item.metadata.source} target="_blank" rel="noreferrer">
                                            <Typography variant="caption">
                                                {item.metadata.source}
                                            </Typography>
                                        </a>
                                    </Box>
                                    <Box sx={{ maxWidth: '30%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <Typography variant="caption">
                                            {new Date(item.metadata.when * 1000).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    ))}
                    {tab === 4 && hasMemoryType('procedural') && response.why.memory.procedural.map((item, index) => (
                        <Box position="relative" key={index} sx={{ marginBottom: 4 }}>
                            <Card  elevation={0} key={index} sx={{ backgroundColor: '#f5f5f5', paddingTop: '10px',  borderRadius: '10px'}}>
                                <Box 
                                    sx={{ 
                                        position: 'absolute', 
                                        top: '-0px', 
                                        left: '50%', 
                                        transform: 'translate(-50%, -50%)', 
                                        backgroundColor: 'black', 
                                        color: 'white', 
                                        borderRadius: '6px', 
                                        width: 50, 
                                        height: 20, 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        fontWeight: 'bold', 
                                        fontSize: '0.8rem'
                                    }}
                                >
                                    <Typography variant="body" component="div">
                                    {item.score.toFixed(3)}
                                    </Typography>
                                </Box>
                            <CardContent>
                                <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                                    {item.page_content}
                                </Typography>
                                <Box display="flex" justifyContent="space-between" marginTop={2}>
                                    <Box sx={{ maxWidth: '65%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <Typography variant="caption">
                                            {item.metadata.source}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ maxWidth: '30%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <Typography variant="caption">
                                            {new Date(item.metadata.when * 1000).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    ))}
                </div>
            </Drawer>
        </div>
    );
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDrawer-paper::-webkit-scrollbar {
    display: none;
  }
.MuiDrawer-paper {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.drawer-content {
  width: 40vw; 
  padding: 20;
  min-width:40vw;
  position: relative; 
}

@media (max-width: 600px) {
  .drawer-content {
      width: 100%; /* Larghezza al 100% per dispositivi mobile in portrait mode */
      min-width: 100%; /* Larghezza minima al 100% */
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;AACF;IACI,wBAAwB,GAAG,gBAAgB;IAC3C,qBAAqB,GAAG,YAAY;EACtC;;AAEF;EACE,WAAW;EACX,WAAW;EACX,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE;MACI,WAAW,EAAE,8DAA8D;MAC3E,eAAe,EAAE,6BAA6B;EAClD;AACF","sourcesContent":[".MuiDrawer-paper::-webkit-scrollbar {\n    display: none;\n  }\n.MuiDrawer-paper {\n    -ms-overflow-style: none;  /* IE and Edge */\n    scrollbar-width: none;  /* Firefox */\n  }\n\n.drawer-content {\n  width: 40vw; \n  padding: 20;\n  min-width:40vw;\n  position: relative; \n}\n\n@media (max-width: 600px) {\n  .drawer-content {\n      width: 100%; /* Larghezza al 100% per dispositivi mobile in portrait mode */\n      min-width: 100%; /* Larghezza minima al 100% */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// src/contexts/SessionContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    const savedSession = localStorage.getItem('sessionId');
    if (savedSession) {
      setSessionId(savedSession);
    }
  }, []);

  const login = (id) => {
    setSessionId(id);
    localStorage.setItem('sessionId', id);
  };

  const logout = () => {
    setSessionId(null);
    localStorage.removeItem('sessionId');
  };

  return (
    <SessionContext.Provider value={{ sessionId, login, logout }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);